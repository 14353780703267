<template>
  <Modal :display="display" @close="$emit('close')">
    <h5 class="text-black text-lg font-bold">
      We have sent a code to your work email.
    </h5>

    <p class="mt-4 text-sm">
      Just to be sure you have access to the email you provided
    </p>
    <div class="relative">
      <Input
        class="mt-14"
        placeholder="Enter OTP"
        width="w-full"
        v-model="otp"
        type="number"
        errorText="OTP must be 6 characters long"
        :validation="rules.otp"
        @valid="valid.otp = $event"
        hideErrorIcon
      />
      <!-- <Button
        v-if="showResendButton"
        text="Resend OTP"
        radius="3xl"
        background="lightRed"
        color="brandRed"
        p="py-1.5 px-2.5"
        fontSize="text-xs"
        width="w-28"
        class="otp-button z-10"
        @click="resendOtp"
        :loading="resendLoading"
      /> -->
      <SecondaryButton
        v-if="showResendButton"
        class="otp-button z-10"
        text="Resend OTP"
        :loading="resendLoading"
        width="w-28"
        @click="resendOtp"
      />
    </div>

    <Button
      text="Continue"
      width="w-full"
      @click="submitOtp"
      class="mb-12"
      :disabled="disableButton"
      :loading="otpLoading"
      shadow
    />
    <div class="spam-notice mb-4 mt-10">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
          fill="#0EA2F5"
        />
      </svg>
      <p class="text-sm">
        If you don’t see our email in your inbox, please check your spam folder
      </p>
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";
import { SUBMIT_EMPLOYMENT_OTP } from "@/utils/api/userInfo.js";
import { Button, SecondaryButton } from "@/UI/Button";
import { Input } from "@/UI/Input";
import { Modal } from "@/UI/Modals";
export default {
  components: {
    Button,
    Input,
    Modal,
    SecondaryButton,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: () => ({
    otpLoading: false,
    resendLoading: false,
    otp: "",
    valid: {
      otp: false,
    },
    showResendButton: false,
  }),
  computed: {
    rules() {
      return {
        otp: this.otp.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.handleDelayOtpButton();
  },
  methods: {
    ...mapActions("userInfo", ["resendEmploymentOtp"]),
    ...mapActions("notification", ["showAlert", "showToast"]),
    submitOtp() {
      if (Object.values(this.valid).includes(false)) {
        this.showAlert({
          display: true,
          description: "Please enter a valid OTP",
          type: "error",
          title: "Validation Error",
        });
        return false;
      } else {
        this.otpLoading = true;
        SUBMIT_EMPLOYMENT_OTP(this.otp)
          .then(() => {
            this.showAlert({
              display: true,
              type: "success",
              description: "You employment data has been successfully updated",
            });
            this.otpLoading = false;
            this.$emit("completed");
          })
          .catch((error) => {
            this.showAlert({
              display: true,
              type: "error",
              description: error.response.data.message,
            });
            this.otpLoading = false;
          });
      }
    },
    resendOtp() {
      this.resendLoading = true;
      this.resendEmploymentOtp()
        .then(() => {
          this.showToast({
            display: true,
            description: "OTP succesfully sent to your email",
            icon: "success",
            type: "success",
          });
          this.resendLoading = false;
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.resendLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
  },
};
</script>
<style scoped>
.otp-button {
  position: absolute;
  right: 0;
  top: 0.2rem;
}
</style>
