var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { display: _vm.display },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("h5", { staticClass: "text-black text-lg font-bold" }, [
        _vm._v(" We have sent a code to your work email. "),
      ]),
      _c("p", { staticClass: "mt-4 text-sm" }, [
        _vm._v(" Just to be sure you have access to the email you provided "),
      ]),
      _c(
        "div",
        { staticClass: "relative" },
        [
          _c("Input", {
            staticClass: "mt-14",
            attrs: {
              placeholder: "Enter OTP",
              width: "w-full",
              type: "number",
              errorText: "OTP must be 6 characters long",
              validation: _vm.rules.otp,
              hideErrorIcon: "",
            },
            on: {
              valid: function ($event) {
                _vm.valid.otp = $event
              },
            },
            model: {
              value: _vm.otp,
              callback: function ($$v) {
                _vm.otp = $$v
              },
              expression: "otp",
            },
          }),
          _vm.showResendButton
            ? _c("SecondaryButton", {
                staticClass: "otp-button z-10",
                attrs: {
                  text: "Resend OTP",
                  loading: _vm.resendLoading,
                  width: "w-28",
                },
                on: { click: _vm.resendOtp },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("Button", {
        staticClass: "mb-12",
        attrs: {
          text: "Continue",
          width: "w-full",
          disabled: _vm.disableButton,
          loading: _vm.otpLoading,
          shadow: "",
        },
        on: { click: _vm.submitOtp },
      }),
      _c("div", { staticClass: "spam-notice mb-4 mt-10" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 20 20",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                fill: "#0EA2F5",
              },
            }),
          ]
        ),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v(
            " If you don’t see our email in your inbox, please check your spam folder "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }